<template>
  <v-container
    fluid
    :class="$vuetify.breakpoint.mdAndUp ? 'pa-0' : 'px-4 py-0'"
    :style="$vuetify.theme.dark ? 'background:black' : 'background:white'"
  >
    <div
      class="d-flex py-4"
      :style="
        $vuetify.breakpoint.mdAndUp
          ? ' height: 320px'
          : $vuetify.breakpoint.smAndUp
          ? 'height: 174px'
          : ''
      "
    >
      <div
        style="width: 1280px"
        class="mx-auto"
        :class="
          $vuetify.breakpoint.mdAndUp
            ? $vuetify.breakpoint.md && $i18n.locale != 'ko'
              ? 'px-3 my-auto'
              : 'px-5 my-auto'
            : 'my-0'
        "
      >
        <div class="mx-auto d-flex" v-if="$vuetify.breakpoint.mdAndUp">
          <template v-for="(data, i) in footer">
            <div
              class="d-flex text-left onSurface_light--text px-0"
              style="flex-direction: column; width: 140px; gap: 12px"
              :key="data.title"
            >
              <span
                class="body-1 bold pa-0"
                style="cursor: pointer"
                @click="handleClick(data)"
              >
                {{ $t("pageFooter." + data.title) }}
              </span>
              <span
                v-for="item in data.contents"
                :key="item.content"
                class="body-1"
                style="cursor: pointer"
                @click="handleClick(item)"
              >
                {{ $t("pageFooter." + item.content) }}
              </span>
            </div>
            <v-spacer :key="data.id" v-if="i != footer.length - 1" />
          </template>
        </div>

        <v-divider
          v-if="$vuetify.breakpoint.mdAndUp"
          class="my-5 onSurface_light"
          style="border-color: #dedede"
        />

        <div
          style="flex-direction: column"
          :style="$vuetify.breakpoint.mdAndUp ? 'gap:10px' : 'gap:14px'"
          class="mx-auto body-2 d-flex onSurface_light--text"
        >
          <v-img
            max-height="26px"
            max-width="fit-content"
            contain
            :src="logoSrc"
            position="center left"
          />
          <div
            v-if="$vuetify.breakpoint.smAndUp"
            class="d-flex"
            :style="
              $vuetify.breakpoint.mdAndUp
                ? ''
                : 'flex-direction:column; gap:4px'
            "
          >
            <div class="d-flex">
              <span>{{ $t("pageFooter.company") }}</span>
              <v-divider
                vertical
                :class="$vuetify.breakpoint.mdAndUp ? 'mx-3' : 'mx-2'"
                class="onSurface_light my-auto"
                :style="$vuetify.breakpoint.mdAndUp ? '' : 'height:10px'"
              />
              <span>{{ $t("pageFooter.phone") }}</span>
            </div>

            <v-divider
              v-if="$vuetify.breakpoint.mdAndUp"
              vertical
              :class="$vuetify.breakpoint.mdAndUp ? 'mx-3' : 'mx-2'"
              class="onSurface_light my-auto"
              :style="
                $vuetify.breakpoint.mdAndUp ? 'height: 14px' : 'height:10px'
              "
            />

            <div class="d-flex">
              <span>{{ $t("pageFooter.address") }}</span>
              <v-divider
                vertical
                :class="$vuetify.breakpoint.mdAndUp ? 'mx-3' : 'mx-2'"
                class="onSurface_light my-auto"
                :style="$vuetify.breakpoint.mdAndUp ? '' : 'height:10px'"
              />

              <span>{{ $t("pageFooter.registration1") }}</span>
              <v-divider
                v-if="$vuetify.breakpoint.lgAndUp || this.$i18n.locale === 'ko'"
                vertical
                :class="$vuetify.breakpoint.mdAndUp ? 'mx-3' : 'mx-2'"
                class="onSurface_light my-auto"
                :style="$vuetify.breakpoint.mdAndUp ? '' : 'height:10px'"
              />
              <span
                v-if="$vuetify.breakpoint.lgAndUp || this.$i18n.locale === 'ko'"
                >{{ $t("pageFooter.registration2") }}</span
              >
            </div>
          </div>

          <div
            class="d-flex"
            :style="
              $vuetify.breakpoint.mdAndUp ? ' ' : 'flex-direction:column;'
            "
          >
            <div
              :class="$vuetify.breakpoint.mdAndUp ? 'd-flex align-center' : ''"
              style="gap: 26px"
            >
              <div
                v-if="$vuetify.breakpoint.smAndUp"
                class="d-flex align-center"
              >
                <div>
                  <span
                    class="body-2"
                    style="cursor: pointer"
                    @click="
                      openLink(
                        'https://docs.madeall3d.com/660dd9c7-43aa-4824-9eef-e571d143dd2d',
                      )
                    "
                  >
                    {{ $t("pageFooter.privacyPolicy") }}
                  </span>
                </div>
                <v-divider
                  vertical
                  :class="$vuetify.breakpoint.mdAndUp ? 'mx-3' : 'mx-2'"
                  class="onSurface_light my-auto"
                  :style="
                    $vuetify.breakpoint.mdAndUp
                      ? 'height: 14px;'
                      : 'height:10px'
                  "
                />
                <div>
                  <span
                    class="body-2"
                    style="cursor: pointer"
                    @click="
                      openLink(
                        'https://docs.madeall3d.com/99db812a-9497-402b-af98-71b329c04abc',
                      )
                    "
                  >
                    {{ $t("pageFooter.termsOfUse") }}
                  </span>
                </div>
                <v-divider
                  vertical
                  :class="$vuetify.breakpoint.mdAndUp ? 'mx-3' : 'mx-2'"
                  class="onSurface_light my-auto"
                  :style="
                    $vuetify.breakpoint.mdAndUp ? 'height: 14px' : 'height:10px'
                  "
                />
                <div>
                  <span
                    class="body-2"
                    style="cursor: pointer"
                    @click="
                      openLink(
                        'https://docs.madeall3d.com/a7ed6b0b-92db-4494-adf9-c0a7491c9c1f',
                      )
                    "
                  >
                    {{ $t("pageFooter.marketingTerms") }}
                  </span>
                </div>
              </div>
              <div class="d-flex align-center body-2">
                <v-icon size="1em" color="onSurface_light"
                  >mdi-copyright</v-icon
                >
                <span> 2023 MadeAll Inc. All rights reserved.</span>
              </div>
            </div>
            <v-spacer
              :style="$vuetify.breakpoint.mdAndUp ? '' : 'height:14px'"
            />
            <div class="d-flex align-center">
              <v-btn
                @click="changeDarkMode()"
                icon
                height="1em"
                width="1em"
                class="pa-0"
                x-small
              >
                <v-icon size="14" class="onSurface_light--text">
                  {{
                    $vuetify.theme.dark
                      ? "mdi-weather-sunny"
                      : "mdi-weather-night"
                  }}
                </v-icon>
              </v-btn>
              <v-btn @click="changeLang()" text height="1em" class="ml-2 pa-0">
                <span class="body-2 onSurface_light--text text-capitalize">
                  {{ $t("pageFooter.language") }}
                </span>
              </v-btn>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
export default {
  computed: {
    logoSrc() {
      const language = this.$i18n.locale === "en" ? "en" : "ko";
      const theme = this.$vuetify.theme.dark ? "Dark" : "Light";
      return require(`@/assets/branding/Mono Logo(${language})_${theme}.svg`);
    },
  },
  methods: {
    navigateTo(route, id) {
      if (this.$router.currentRoute.name !== route) {
        this.$router.push({ name: route }).then(() => {
          this.scrollToId(id);
        });
      } else {
        this.scrollToId(id);
      }
    },
    scrollToId(id) {
      if (id) {
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      } else {
        window.scrollTo(0, 0);
      }
    },
    handleClick(item) {
      if (item.externalLink) {
        window.open(item.externalLink);
      } else if (item.link) {
        this.navigateTo(item.link, item.id);
      } else {
        this.notReady();
      }
    },
    openLink(link) {
      window.open(link);
    },
    notReady() {
      alert("준비중입니다.");
    },
    changeLang() {
      this.$i18n.locale = this.$i18n.locale === "ko" ? "en" : "ko";
    },
    changeDarkMode() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    },
  },
  data() {
    return {
      footer: [
        {
          title: "madeAll",
          link: "AboutUs",
          contents: [
            {
              content: "goals",
              link: "",
              externalLink:
                "https://docs.madeall3d.com/fa4605bf-8fd9-4bfc-9a9f-88c85478f01d",
            },
            {
              content: "investment",
              link: "",
              externalLink:
                "https://docs.madeall3d.com/fa4605bf-8fd9-4bfc-9a9f-88c85478f01d",
            },
            {
              content: "brandResource",
              link: "",
              externalLink:
                "https://docs.madeall3d.com/fa4605bf-8fd9-4bfc-9a9f-88c85478f01d",
            },
          ],
        },
        {
          title: "make",
          link: "Service",
          contents: [
            {
              content: "makeAbout",
              link: "Service",
            },
            {
              content: "makeOrder",
              link: "Order",
            },
          ],
        },
        {
          title: "printer",
          link: "Products",
          contents: [
            {
              content: "products",
              link: "Products",
            },
            {
              content: "documents",
              link: "Products",
              id: "document",
            },
          ],
        },
        {
          title: "edu",
          link: "Education",
          contents: [
            {
              content: "eduAbout",
              link: "Education",
            },
            {
              content: "eduContents",
              link: "Education",
              id: "contents",
            },
            {
              content: "eduPortfolio",
              link: "Education",
              id: "case",
            },
          ],
        },
        {
          title: "makerSpace",
          link: "",
          contents: [
            {
              content: "aboutSpace",
              link: "",
            },
            {
              content: "spaceInfo",
              link: "",
            },
          ],
        },
      ],
    };
  },
};
</script>

<style></style>
