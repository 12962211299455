<template>
  <div>
    <page-appbar />
    <div
      style="position: relative; background: var(--v-background_normal-base)"
      :style="
        $vuetify.breakpoint.mdAndUp ? 'margin-top: 60px' : 'margin-top: 52px'
      "
    >
      <div style="z-index: 0; position: absolute; width: 100vw">
        <v-img
          :src="require('@/assets/images/MyPage/MyPageBackground.jpg')"
          max-height="433px"
          min-height="300px"
          gradient="to top, rgba(0,0,0,0.6), rgba(19,42,86,0.6)"
        />
      </div>
      <v-container
        fluidd
        pa-0
        class="d-flex"
        style="max-width: 1380px !important"
      >
        <v-card
          :class="$vuetify.breakpoint.smAndUp ? 'mx-10' : 'mx-4'"
          style="padding-bottom: 60px"
          :style="
            $vuetify.breakpoint.lgAndUp
              ? 'padding-top: 100px'
              : $vuetify.breakpoint.smAndUp
              ? 'padding-top: 80px'
              : 'padding-top: 60px'
          "
          color="transparent"
          width="100%"
          flat
        >
          <div class="d-flex flex-column">
            <div
              class="font-weight-bold white_light_global--text"
              :class="$vuetify.breakpoint.lgAndUp ? 'h1' : 'h2'"
            >
              {{ $t("mypage.title") }}
            </div>
            <div
              class="font-weight-bold white_light_global--text mt-5"
              :class="$vuetify.breakpoint.lgAndUp ? 'title-1 ' : 'subtitle-1'"
              style="max-width: 480px"
            >
              {{ $t("mypage.subtitle") }}
            </div>
          </div>
          <div
            :style="
              $vuetify.breakpoint.lgAndUp
                ? 'height: 80px'
                : $vuetify.breakpoint.smAndUp
                ? 'height: 60px'
                : 'height: 40px'
            "
          />
          <v-card-text class="pa-0">
            <router-view
              style="z-index: 1; position: relative; width: 100%"
              :userData="userData"
              :orderData="orderData"
              @saveData="saveData"
            />
          </v-card-text>
        </v-card>
      </v-container>
    </div>
    <page-footer />
  </div>
</template>

<script>
import PageAppbar from "../../components/Appbar/PageAppbar.vue";
import PageFooter from "../../components/Footer/PageFooter.vue";
import setMeta from "@/utils/setMeta";
import _ from "lodash";
import API from "@/API/auth.js";
import constants from "@/utils/constants";

export default {
  components: {
    PageAppbar,
    PageFooter,
  },
  data() {
    return {
      pageIndex: 20,
      userData: constants.userInterface,
      orderData: [],
    };
  },
  beforeMount() {
    setMeta({ title: "마이페이지", description: "회원 정보 및 주문 내역" });
    this.getUserData();
  },
  methods: {
    async getUserData() {
      const authUser = await this.$store.getters["auth/user/GET_USER"];
      const filteredAuthUser = _.pick(
        authUser,
        Object.keys(constants.userInterface)
      );
      filteredAuthUser.phoneNumber = "";
      this.userData = _.merge(this.userData, filteredAuthUser);

      const storeUser = await API.getStoreUserData();
      const filteredStoreUser = _.pick(
        storeUser,
        Object.keys(constants.userInterface)
      );
      this.userData = _.merge(this.userData, filteredStoreUser);
      this.originalData = _.cloneDeep(this.userData);
    },
    async saveData(data) {
      this.userData = _.cloneDeep(data);
      this.$router.push("/mypage");
      this.$axios.patch("users/" + data.uid, this.userData);
    },
  },
};
</script>
